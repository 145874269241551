import {Injectable} from "@angular/core";
import {tokenStore} from "../../options/settings"

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() {
  }

  setToken(data: any) : void {
    localStorage.removeItem(tokenStore);
    localStorage.setItem(tokenStore, JSON.stringify(data));

  }

  getAuthToken() : string | null {
    //return this.cookieService.get(access_token_key);
    let tokens = this.getToken();
    if (tokens == null)
      return null;
    return tokens.accessToken;
  }

  getAuthTokenType() : string | null {
    //return this.cookieService.get(access_token_type);
    let tokens = this.getToken();
    if (tokens == null)
      return null;
    return tokens.tokenType;
  }

  getToken() : any | null {
    let tokensJson = localStorage.getItem(tokenStore);
    if (tokensJson == null)
      return null;

    return JSON.parse(tokensJson);
  }

  deleteToken() : void {
    localStorage.removeItem(tokenStore);

  }
}


