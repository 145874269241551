// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export interface Environment {
  production: Window|boolean;
  demo: Window|boolean;
  searchBotDisallow: Window|boolean;
  apiEndpoint: Window|string;
  allowedDomains: any;
  trustedNetClientId: Window|string;
  authEndPoint: Window|string;
  announcementApiEndpoint: Window|string,
  fileServerEndpoint: Window|string,
  supportEmail: Window|string,
  infoMessage: Window|string,
  headerTitle: any;
  apiPaths: any;
  services: any;
  menuItems: any;
  projecting: any;
  portfolio: any;
  announcement: any;
  invalidTokenMessage: any;
}

export const environment: Environment = {
  production: window[<any>"env"][<any>"prod"] || false,
  demo: window[<any>"env"][<any>"demo"] || false,
  searchBotDisallow: window[<any>"env"][<any>"searchBotDisallow"] || false,
  apiEndpoint: window[<any>"env"][<any>"apiUrl"],
  trustedNetClientId: window[<any>"env"][<any>"clientId"],
  authEndPoint: window[<any>"env"][<any>"authUrl"],
  allowedDomains: [window[<any>"env"][<any>"allowedDomains"]],
  fileServerEndpoint: window[<any>"env"][<any>"apiUrl"],
  announcementApiEndpoint: window[<any>"env"][<any>"apiUrl"],
  //announcementApiEndpoint: 'http://localhost:8086/api/v1/',
  supportEmail: window[<any>"env"][<any>"supportEmail"],
  infoMessage: window[<any>"env"][<any>"infoMessage"],
  invalidTokenMessage: window[<any>'env'][<any>'invalidTokenMessage'],
  headerTitle: {
    full: window[<any>"env"][<any>"fullHeader"],
    short: window[<any>"env"][<any>"shortHeader"]
  },

  apiPaths: {
    ExamList: "exam",
    Student: "student",
    DictOrganization: "dictorganization",
    DictStudentAcademicState: "dictstudentacademicstate",
    DictStudentBudget: "dictstudentbudget",
    DictStudentBudgetSubCategory: "dictstudentbudgetsubcategory",
    EduGroup: "edugroup",
    Email: "email",
    Languages: "languages",
    OrdersStudyPeriod: "ordersstudyperiod",
    Phone: "phone",
    StudEduGroup: "studedugroup",
    StudentAcademicState: "studentacademicstate",
    StudentBudgetState: "studentbudgetstate",
    StudPerson: "studperson",
    Personal: "person",
    EducationProgram: "eprogram",
    TimeTable: "timetable",
    StudyProgress: "study",
    EducationIntensityData: "intensity",
    EducationBUP: "bup",
    BudgetTime: "btime",
    DirectionActivity: "directionactivity",
    //Portfolio: "portfolio",
    StudPlan: "studplan",
    MiddleControl: "middlecontrol",
    CurrentControl: "currentcontrol",
    DictAttestation: "dictattestation",
    WorkScheduleType: "d/workscheduletype",
  },
  services: window[<any>"env"][<any>"services"],
  menuItems: window[<any>"env"][<any>"menuItems"],
  projecting: {
    daysInWeek: 6,
    apiEndpoint: window[<any>"env"][<any>"LKApi"],
    apiPaths: {
      standard: "EducationStandards",
      standardtype: "EducationStandardTypes",
      studylevel: "EducationLevels",
      qualification: "Qualifications",
      oop: "EducationPrograms",
      faculty: "Faculty",
      kafedra: "Department",
      trainingLevel: "TrainingLevels",
      dictcycle: "DictCycle",
      dictCycleType: "DictCycleType",
      dictComponent: "DictComponent",
      dictComponentType: "DictComponentType",
      dictLessonType: "DictLessonType",
      dictLessonForm: "DictLessonForm",
      dictAcceleratedStudyBase: "DictAcceleratedStudyBase",
      dictFormDesignerDataType: "DictFormDesignerDataType",
      dictWorkCategorySchedule: "DictWorkCategorySchedule",
      dictWorkScheduleStudyProcessType: "DictWorkScheduleStudyProcessType",
      dictWorkStudyPlanType: "DictWorkStudyPlanType",
      educationPrograms: "educationPrograms",
      educationPlan: "educationPlans",
      competenceMatrixService: "competenceMatrix",
      eStandardTypes: "dict/educationStandardTypes",
      dictEducationLevel: "dict/EducationLevels",
      dictQualification: "dict/qualifications",
      dictTrainingLevel: "dict/trainingLevels"
    }
  },
  portfolio: {
    apiPaths: {
      dict: "portfolio/dict",
      document: "portfolio/document",
      achievement: "portfolio/achievement"
    },
    maxFileSize: window[<any>"env"][<any>"portfolio_maxFileSize"] || 4194304,//4194304
  },
  announcement: {
    commentMaxFileSize: window[<any>"env"][<any>"announcement_commentMaxFileSize"] || 5242880,//5242880,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
