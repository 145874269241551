import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { CellClickEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import {SortDescriptor, groupBy, orderBy} from '@progress/kendo-data-query';
import {
  EducationGraphLegend,
  EducationPlanDiscipline,
  LKEducationPlan
} from 'src/app/models/education/LKEducationPlan.model';
import { LKEducationProgram } from 'src/app/models/education/LKEducationProgram.model';
import { LKEducationPlanService } from 'src/app/services/LKEducation/LKEducationBUP/lkeducationPlan.service';
import { EducationStandard } from 'src/app/models/education/EducationStandard.model';
import { DictStudyForm } from 'src/app/models/profile/dictionaries/DictStudyForm.model';
import { Faculty } from 'src/app/models/profile/external/faculty.model';
import { Department } from 'src/app/models/profile/external/department.model';
import { LKStudPlanService } from 'src/app/services/LKStudent/lkstud-plan.service';
import { LKStudEduGroup } from 'src/app/models/profile/lkStudEduGroup.model';
import { LKStudEduGroupService } from 'src/app/services/LKStudent/lkstud-edu-group.service';
import { LKStudPlan } from 'src/app/models/education/LKStudPlan.model';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { LKEduGroup } from 'src/app/models/profile/lkEduGroup.model';
import { DictQualification } from 'src/app/models/education/DictQualification.model';
import {ActivatedRoute, Router} from '@angular/router';
import {
  CellDataType, DictWorkScheduleStudyProcessType, ScheduleCourseData,
  TableDataType,
  TableHeaderType,
  WorkType
} from 'src/app/models/education/education-plan-schedule.model';
import * as _ from "lodash";
import { DictWorkScheduleStudyProcessTypeService } from 'src/app/services/LKEducation/DictWorkScheduleStudyProcessType/dict-work-schedule-study-process-type.service';
import {CommonStatusEnum} from "../../models/enums/common-status.enum";


@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

  public totalData: any[] = [];
  public totalColumns: any[] = [];
  public tableData: TableDataType[] = [];
  public tableHeaderData: TableHeaderType[] = [];
  private emptyCell?: EducationGraphLegend;

  public IntensityMeasure = 36;
  public dictMonth: { [key: string]: string } = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  };

  public commonStatus = CommonStatusEnum;
  public hasLoaded = false;
  public isInstallationSession = false;
  public totalHours = 0;
  public totalLabourHours = 0;
  private selectedDisciplineId = "";
  private tabNumber = String(this.route.snapshot.paramMap.get('tab'));
  public IsTabSelected(id:number)
  {
    if(this.tabNumber==undefined || this.tabNumber=='null' || this.tabNumber=='') this.tabNumber='2';
    return this.tabNumber == id.toString();
  }

  // Конвертация года или месяцев
  yearConversion?: any = {1: 'год', 2: 'года', 3: 'года', 4: 'года', 5: 'лет', 6: 'лет', 7: 'лет', 8: 'лет', 9: 'лет', 10: 'лет', 11: 'лет'  };
  monthConversion?: any = {1: 'месяц', 2: 'месяца', 3: 'месяца', 4: 'месяца', 5: 'месяцев', 6: 'месяцев', 7: 'месяцев', 8: 'месяцев', 9: 'месяцев',
   10: 'месяцев', 11: 'месяцев', 12: 'месяцев'}

  // Для проверки планов из группы и из студента
  public groupEducationPlan? = ""
  public studEducationPlan? = ""

  // Table values
  // Направление подготовки
  public educationProgramName? = "";
  public trainingLevelId?: string;
  // Технология обучения
  public studyTechnologyName? = "";
  // Профиль
  public profilingCourseNumber = 1;
  public hasProfile():boolean { return this.profilingCourseNumber <= (this.studEduGroup?.eduGroup?.courseNum ?? 1)}
  public profileValue? = "";
  // Форма обучения
  public studyFormValue? = "";
  // Длительность обучения
  public yearsLength = 0;
  public monthsLength = 0;
  // Квалификация
  public qualificationName? = "";

  // Экз.часы
  public examHours = 0;
  public examLaboutIntensity = 0;

  // Условные обозначения
  public mapLegend: EducationGraphLegend[] = [
    /*{ Name: 'Каникулы', Symbol: 'К', Color: '#FFEB38'},
    { Name: 'Учебные занятия по дисциплинам (модулям)', Symbol: '^', Color: '#FFFFFF'},
    { Name: 'Неучебная неделя', Symbol: '/', Color: '#FFFFFF'},
    { Name: 'Промежуточная аттестация', Symbol: '::', Color: '#FF0000'},
    { Name: 'Государственная итоговая аттестация', Symbol: 'Г', Color: '#D65EEA'},
    { Name: 'Производственная практика', Symbol: 'X', Color: '#D9D9D9'},
    { Name: 'Преддипломная практика', Symbol: 'П', Color: '#FAFAFA'}*/
  ]

  // StudPerson
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public studyForm: DictStudyForm = {
    studyFormName: '',
    studyFormSName: ''
  }

  public educationStandard: EducationStandard = {
    cipher: '',
    name: '',
    shortName: '',
    dictStandardTypeId: '',
    dictEducationLevelId: '',
    dictTrainingLevelId: ''
  }

  public faculties: Faculty[] = [];
  public faculty: Faculty = {
    name: '-',
    shortName: '-'
  }

  public department: Department = {
    name: '-',
    shortName: '-',
    faculty: this.faculty
  }

  public dictQualification: DictQualification = {
    qualificationName: '',
    qualificationSName: ''
  }

  public studGroupEducationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    dictQualification: this.dictQualification,
    departmentId: '',
    graduatingDepartment: this.department,
    faculty: this.faculty
  }

  public educationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    dictQualification: this.dictQualification,
    departmentId: '',
    graduatingDepartment: this.department,
    faculty: this.faculty
  }

  public educationPlan: LKEducationPlan = {
    educationPlanId: 0,
    educationPlanExternalId: "",
    jsonData: "",
    educationProgramId: '',
    educationProgram: this.educationProgram,
    dictStudyFormId: '',
    dictStudyForm: this.studyForm
  }

  public eduGroup?: LKEduGroup = {
    courseNum: 0,
    groupName: "-",
    faculty: this.faculty,
    facultyId: ""
  }

  public studEduGroup?: LKStudEduGroup = {
    studPersonId: '',
    studentId: '',
    fullName: '',
    eduGroupId: '',
    eduGroup: this.eduGroup
  }

  public studPlan?: LKStudPlan = {
    studentId: '',
    educationPlan: this.educationPlan,
    department: this.department
  }

  public sort: SortDescriptor[] = [
    {
      field: "CourseNumber",
      dir: "asc",
    },
  ];

  public sortCourse: SortDescriptor[] = [
    {
      field: "WeekNumber",
      dir: "asc",
    },
    {
      field: "SerialNumberWeek",
      dir: "asc",
    },
  ];

  public sortEmpty: SortDescriptor[] = [
    {
      field: "weekNumber",
      dir: "asc",
    },
  ];

  public bupData: EducationPlanDiscipline[] = [];

  //public budgetTimesData: any = []; //столбцы аудиторки для сводных данных по бюджету
  //сводные данные по бюджету времени
  /*public budget: any[] = [
    {
      Hours: 0,
      Intensity: 0,
      ControlHours: 0,
      ControlIntensity: 0,
      ContactWorkHours: 0,
      ContactWorkIntensity: 0,
      SelfWorkHours: 0,
      SelfWorkIntensity: 0,
    }
  ]*/


  public gridData: any = this.bupData; //данные для основного грида

  constructor(private educationPlanService: LKEducationPlanService,
    private studPlanService: LKStudPlanService,
    private studEduGroupService: LKStudEduGroupService,
    private studentService: LKStudentService,
    private dictWorkScheduleStudyProcessTypeService: DictWorkScheduleStudyProcessTypeService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public getStudPlan(studentId: string) {
    this.studPlanService.getStudPlan(studentId)
    .subscribe(
      async response => {
        this.studPlan = response;
        this.studEducationPlan = this.studPlan?.planId;
        await delay(500);
        this.hasLoaded = true;

        // Вывод базового учебного плана в таблицу (если есть StudPlan)

        let plan:LKEducationPlan|undefined = this.studPlan?.educationPlan;
        if(plan == null) {
          plan = this.studEduGroup?.eduGroup?.educationPlan ?? undefined;
          this.educationProgramName = this.studGroupEducationProgram?.educationStandard?.cipher + " - " + this.studGroupEducationProgram?.educationStandard?.name;
          this.qualificationName = this.studGroupEducationProgram?.dictQualification?.qualificationName;
          this.trainingLevelId = this.studGroupEducationProgram?.educationStandard?.dictTrainingLevelId;
        }
        else {
          this.educationProgramName = this.studPlan?.educationPlan?.educationProgram?.educationStandard?.cipher + " - " + this.studPlan?.educationPlan?.educationProgram?.educationStandard?.name;
          this.qualificationName = this.studPlan?.educationPlan?.educationProgram?.dictQualification?.qualificationName;
          this.trainingLevelId = this.studPlan?.educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId;
        }

        this.mapLegend = this.filterScheduleLegent(this.mapLegend);
        this.emptyCell = this.mapLegend.find((x) => x.dictWorkCategoryScheduleId == null && !x.isInvolvedCalculating)

        if(plan?.jsonData != null || plan?.jsonData != undefined)
        {
          const arr = JSON.parse(plan?.jsonData);
          // Pass values to HTML
          this.profilingCourseNumber = plan?.educationProgram?.profilingCourseNumber ?? 1;
          this.profileValue = plan?.educationProgram?.name;
          this.studyTechnologyName = arr.DictStudyTechnology.Name;
          this.yearsLength = arr.StudyYears ?? 0;
          this.monthsLength = arr.StudyMonths ?? 0;
          this.studyFormValue = plan?.dictStudyForm?.studyFormName;

          this.isInstallationSession = this.studyFormValue.toLowerCase().includes("заоч")

          //сформируем собственную таблицу с дисциплинами и компонентами
          let planData = [];
          let currentCycleId = '', currentComponent:any;
          for(let discipline of arr.Disciplines)
          {
            if(currentCycleId!=discipline.DictCycleId) {
              planData.push({
                Id: null,
                Cipher: discipline.DictCycleSName,
                DictDisciplineName: discipline.DictCycleName,
                DepartmentName: '',
                ControllingActions: [],
                TotalNumberHours: sumHours(arr.Disciplines, discipline.DictCycleId, null),
                TotalLaborIntensity: sumIntensity(arr.Disciplines, discipline.DictCycleId, null),
                Level: 0
              });
              currentCycleId = discipline.DictCycleId;
            }
            if(currentComponent==undefined || currentComponent.Id!=discipline.DictComponent.Id) {
              if((currentComponent==undefined || currentComponent.ParentDictComponent?.Id!=discipline.DictComponent.ParentDictComponent?.Id)
                && discipline.DictComponent.ParentDictComponent != null)
                planData.push({
                  Active: discipline.CommonStatus === this.commonStatus.Active,
                  Id: null,
                  Cipher: !discipline.DictComponent.ParentDictComponent.HiddenCipher
                    ? discipline.DictCycleSName + '.' + discipline.DictComponent.ParentDictComponent.SName
                    : '',
                  DictDisciplineName: discipline.DictComponent.ParentDictComponent.Name,
                  DepartmentName: '',
                  ControllingActions: [],
                  TotalNumberHours: sumHours(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
                  TotalLaborIntensity: sumIntensity(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
                  Level: 1
                });
              if(!discipline.DictComponent.HiddenTitle)
                planData.push({
                  Active: discipline.CommonStatus === this.commonStatus.Active,
                  Id: null,
                  Cipher: !discipline.DictComponent.HiddenCipher
                    ? discipline.DictCycleSName + '.' + discipline.DictComponent.SName
                    : '',
                  DictDisciplineName: discipline.DictComponent.Name,
                  DepartmentName: '',
                  ControllingActions: [],
                  TotalNumberHours: sumHours(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.Id),
                  TotalLaborIntensity: sumIntensity(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.Id),
                  Level: 1
                });
              currentComponent = discipline.DictComponent;
            }
            planData.push({
              Active: discipline.CommonStatus === this.commonStatus.Active,
              Id: discipline.Id,
              Cipher: discipline.Cipher,
              DictDisciplineName: discipline.DictDisciplineName,
              DepartmentName: discipline.DepartmentSName,
              ControllingActions: discipline.ControllingActions,
              TotalNumberHours: discipline.ChildrenDiscipline && discipline.ChildrenDiscipline.length !== 0 ? sumHours(discipline.ChildrenDiscipline, discipline.DictCycleId, discipline.DictComponent.Id) : discipline.TotalNumberHours,
              TotalLaborIntensity: discipline.ChildrenDiscipline && discipline.ChildrenDiscipline.length !== 0 ? sumIntensity(discipline.ChildrenDiscipline, discipline.DictCycleId, discipline.DictComponent.Id) : discipline.TotalLaborIntensity,
              Level: 2
            });
            for(let el = 0; el < discipline.ElectiveDisciplines.length; el++) {
              planData.push({
                Active: discipline.ElectiveDisciplines[el].CommonStatus === this.commonStatus.Active,
                Id: discipline.Id,
                Cipher: discipline.Cipher + "." + discipline.ElectiveDisciplines[el].SerialNumber,
                DictDisciplineName: discipline.ElectiveDisciplines[el].DictDisciplineName,
                DepartmentName: discipline.ElectiveDisciplines[el].DepartmentSName,
                ControllingActions: [],
                Level: 3
              });
            }
          }

          //посчитаем сводные данные
          this.bupData = arr.Disciplines;
          this.gridData = planData;

          let semesterNumbers = [...new Set(arr.Semesters.map((item:any) => item.Number))] as number[];
          this.totalColumns = [...semesterNumbers.map((item:number) => ({field: '_'+item, title: item}))];
          this.totalData = [];

          let tmp = {name: "Аудиторная нагрузка, ак.ч."};
          for (let col of this.totalColumns) {
            Object.defineProperty(tmp, col.field, { value: sumContactWork(arr.Disciplines, col.title) });
          }
          this.totalData.push(tmp);

          let actionsArr = arr.Disciplines
            .filter((item: any) => item.Cipher && item.CommonStatus === this.commonStatus.Active)
            .flatMap((item:any) => item.ControllingActions);
          let actions = [...new Map(actionsArr
            .map((item:any) => ([item.DictControlActionName, {id: item.DictControlActionId, name: item.DictControlActionName}])))
            .values()].sort((a:any, b:any) => a.name.localeCompare(b.name)) as any[];
          for(let action of actions) {
            tmp = {name: action.name + ', шт.'};
            for (let col of this.totalColumns) {
              Object.defineProperty(tmp, col.field, { value: countActions(actionsArr, col.title, action.id, null, null) });
            }
            this.totalData.push(tmp);
          }
          let cnt = 0;
          tmp = {name: "Курсовая работа, шт."};
          for (let col of this.totalColumns) {
            let tmpRes = countActions(actionsArr, col.title, null, true, false);
            cnt += tmpRes;
            Object.defineProperty(tmp, col.field, { value: tmpRes });
          }
          //если вся строка нулевая - скроем
          if(cnt > 0)
            this.totalData.push(tmp);
          cnt = 0;
          tmp = {name: "Курсовой проект, шт."};
          for (let col of this.totalColumns) {
            let tmpRes = countActions(actionsArr, col.title, null, false, true)
            cnt += tmpRes;
            Object.defineProperty(tmp, col.field, { value: tmpRes });
          }
          //если вся строка нулевая - скроем
          if(cnt > 0)
            this.totalData.push(tmp);


          /*this.budget[0].Hours= sumHours(arr.Disciplines, null, null);
          this.budget[0].Intensity= sumIntensity(arr.Disciplines, null, null);
          this.budget[0].ControlHours= sumControl(arr.Disciplines, null);
          this.budget[0].ControlIntensity= this.budget[0].ControlHours / this.IntensityMeasure;
          this.budget[0].ContactWorkHours= sumContactWork(arr.Disciplines, null);
          this.budget[0].ContactWorkIntensity= this.budget[0].ContactWorkHours / this.IntensityMeasure;
          this.budget[0].SelfWorkHours= sumSelfWork(arr.Disciplines, null);
          this.budget[0].SelfWorkIntensity= this.budget[0].SelfWorkHours / this.IntensityMeasure;
          if(arr.Disciplines.length > 0) {
            this.budgetTimesData = arr.Disciplines[0].ControllingActions[0].TypeWorks
              .filter((item:any) => item.ClassroomWork)
              .map((item:any) => ({
                name: item.SName,
                value: sumTypeWorkHours(arr.Disciplines, item.Name)
              }));
          }*/

          //данные для графика
          //легенда
          /*this.mapLegend = [...new Map(arr.EducationalProcessSchedules.map((item:any) => ([item.WorkType.Name, {
            Color: item.WorkType.Color,
            Symbol: item.WorkType.DisplayedValue,
            Name: item.WorkType.Name
          }]))).values()] as EducationGraphLegend[];*/

          let defaultDate = new Date(arr.YearAdmission,8,1);
          //заголовки для графика - нужен массив дат, от минимальной за курс до максимальной
          let semesterDates = arr.Semesters
            .filter((sem: any) => sem.StartTraining != null)
            .map((item: any) => ({
              semesterNumber: item.Number,
              semesterBeginWeekNumber: null,
              courseNumber: item.CourseNumber,
              installationSessionBegin: item.InstallationSessionBegin,
              installationSessionEnd: item.InstallationSessionEnd,
              //все даты начала семестра преобразуем в текущий учебный год для равенства
              StartTraining: item.StartTraining != null
                ? DateFromUTCAsLocal(item.StartTraining)
                : null
            }));
          //уникальные даты, без пустых
          let distinctDates = [...new Set(arr.Semesters.map((item:any) => new Date(arr.YearAdmission, new Date(item.StartTraining).getMonth(), new Date(item.StartTraining).getDate())))] as Date[];

          //let minDate = new Date(Math.min(...distinctDates.map((item) => item.getTime())));

          let minDate = new Date(Math.min(...semesterDates.map((s: any) => s.StartTraining.getTime())));

          const maxDate = new Date(Math.max(...distinctDates.map((x: Date) => new Date(x).setDate(x.getDate() + 52 * 7)))); //+ 1 год
          semesterDates.forEach((x: any) => {
            x.semesterBeginWeekNumber = moment(minDate).diff(moment(x.StartTraining), 'week') + 1
          });

          //посчитаем дату начала обучения для всего плана (какая дата чаще больше по курсам - та и главная)
          //минимальные даты по курсам
          let courseDates = semesterDates.reduce((arr: any[], sem: any) => {
            if(arr.filter(x => x.courseNumber == sem.courseNumber).length == 0) {
              arr.push({
                courseNumber: sem.courseNumber,
                minDate: new Date(Math.min(...semesterDates
                  .filter((s: any) => s.StartTraining != null && sem.courseNumber == s.courseNumber)
                  .map((s: any) => s.StartTraining.getTime())))
              })
            }
            return arr;
          }, []);
          //агрегирование для подсчета самой частой даты
          let courseDatesStatistic = courseDates.reduce((arr: any[], course: any) => {
            let item = arr.find(x => x.minDate.getTime() == course.minDate.getTime())
            if(item) item.count ++;
            else arr.push({ count: 1, minDate: course.minDate });
            return arr;
          }, []).sort((a: any, b: any) => a.count < b.count ? 1 : -1);
          //если у нас 2 победителя - тогда добавим дефолтную дату, чтобы внести ясность
          if(courseDatesStatistic.length>1 && courseDatesStatistic[0].count == courseDatesStatistic[1].count){
            let item = courseDatesStatistic.find((x: any) => x.minDate.getTime() == defaultDate.getTime())
            if(item) item.count++;
            courseDatesStatistic = courseDatesStatistic.sort((a: any, b: any) => a.count < b.count ? 1 : -1);
          }
          //и вот и нас есть дата начала обучения по плану
          let averageMinDate = courseDatesStatistic.length > 0 ? courseDatesStatistic[0].minDate : defaultDate;

          let dateYearEnd = new Date(minDate);
          dateYearEnd.setFullYear(minDate.getFullYear() + 1);
          dateYearEnd.setDate(minDate.getDate() - 1);

          let yearEndOffset = moment(dateYearEnd).diff(moment(new Date(minDate).setDate(minDate.getDate() + 52 * 7)), 'days');

          if(!this.leapYear(minDate.getFullYear() + 1))
              yearEndOffset++;

          dateYearEnd.setDate(dateYearEnd.getDate() - 6);

          let graphDates:any[] = [];
          let i = 1;
          //массив начал и концов недели в диапазоне от minDate до maxDate
          //считаем, что minDate - эталонное начало недели
          while(minDate < maxDate) {
            let leapOffset = this.leapYear(minDate.getFullYear()) && (minDate.getMonth() >= 2 || minDate.getMonth() == 1 && minDate.getDate() == 29) ? 1 : 0;

            let tmpDate = new Date(minDate);
            tmpDate.setDate(tmpDate.getDate() + 6);

            let leapOffsetEnd = this.leapYear(tmpDate.getFullYear()) && (tmpDate.getMonth() >= 2 || tmpDate.getMonth() == 1 && tmpDate.getDate() == 29) ? 1 : 0;

            const tmpStartDate = new Date(new Date(minDate).setDate(minDate.getDate() + leapOffset));
            const tmpEndDate = new Date(new Date(tmpDate).setDate(tmpDate.getDate() + leapOffsetEnd));

            graphDates.push({
              start: new Date(new Date(minDate).setDate(minDate.getDate() + leapOffset + (tmpStartDate > dateYearEnd ? yearEndOffset : 0))),
              end: new Date(new Date(tmpDate).setDate(tmpDate.getDate() + leapOffsetEnd + (tmpEndDate > dateYearEnd ? yearEndOffset : 0))),
              weekNumber: i,
              title: (Math.round(moment(minDate).diff(moment(new Date(averageMinDate.getFullYear(), 8, 1)), 'days') / 7) % 52 + 52) % 52 + 1
            });
            minDate.setDate(minDate.getDate() + 7);
            i++;
          }
          this.getTableHeaderData(graphDates);

          //сам график
          this.getTableData(
            groupBy(orderBy(arr.EducationalProcessSchedules, this.sort), [{field:"CourseNumber"}]),
            semesterDates,
            graphDates
          );
        }
      },
        error => {
        this.hasLoaded = true;
        }
    )
  }

  private filterScheduleLegent(legend: EducationGraphLegend[]) : EducationGraphLegend[]{
    return legend.filter(
      (item) =>
        item.dictTrainingLevelId === this.trainingLevelId)
  }

  public leapYear(year: number)
  {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  public getTableData(processScheduleData: any[], sourceSemesters: any[], graphDates: any[]) {
    if(sourceSemesters.length === 0  || graphDates.length === 0)
      return;
    let templateTableData: TableDataType[] = [];
    this.tableData = [];

    //пройдем по каждому курсу
    processScheduleData.forEach((sourceItem: any) => {

      let semesterNumber = sourceItem.items[0]?.SemesterNumber
        ? sourceItem.items[0].SemesterNumber
        : 1;

      sourceItem.items = orderBy(sourceItem.items, this.sortCourse)

      let item = {
        workTypes: sourceItem.items,
        courseNumber: sourceItem.value,
        semesters: sourceSemesters.filter((x: any)=> x.courseNumber == sourceItem.value),
      } as ScheduleCourseData;

      //сдвиг для курса целиком, если начало курса отличается от остальных
      let courseDateMin = new Date(Math.min(...item.semesters.map((sem:any) => sem.StartTraining)));
      let minDate = new Date(Math.min(...sourceSemesters.map((s: any) => s.StartTraining.getTime())));
      item.workTypes.forEach(week => week.WeekNumber += moment(courseDateMin).diff(moment(new Date(minDate)), 'week'))

      /* {
         "CourseNumber": 1,
         "SemesterNumber": null,
         "DaysAmount": 6,
         "SerialNumberWeek": 0,
         "WeekNumber": 49,
         "WorkType": {
         "CategoryId": "d36da979-6286-4a9f-8476-aedfe22cd374",
           "Name": "Каникулы",
           "ShortName": "",
           "Symbol": "К",
           "SymbolMMIS": "К",
           "DisplayedValue": "К",
           "Color": "#FFFF00",
           "Calc": true
       }
       }*/

      /*let semesterNumber = item.workTypes[0]?.SemesterNumber
        ? item.workTypes[0].SemesterNumber
        : 1;
      let isNextSemester = false;
      let nextSemesterIndex = 1;
      let semestrBeginWeekNumber = item.semesters.map((semester) =>
        semester.semesterBeginWeekNumber ? semester.semesterBeginWeekNumber : 0
      );*/
      //this.educationPlanScheduleService.semestersByCourse[item.courseNumber] = JSON.parse(JSON.stringify(item.semesters));

      const weeksNumber = item.workTypes.reduce(
        (weeks: CellDataType[], type: WorkType) => {
          if (!type.SerialNumberWeek) {
            /*if (
              isNextSemester &&
              semesterNumber !==
              item.semesters[nextSemesterIndex]?.semesterNumber &&
              (type.WorkType.DictWorkCategoryScheduleId === '55c9b980-e214-4e28-9d3d-7da90a625f05' ||
                type.WorkType.DictWorkCategoryScheduleId === '0c39ac39-3920-41bc-83ba-9f996976f6ba' ||
                type.WorkType.DictWorkCategoryScheduleId === '1c860063-aab3-4acd-a958-ec58d2551927' ||
                type.WorkType.DictWorkCategoryScheduleId === 'e920e5a5-34fd-453b-9c36-185cb47e923b' ||
                type.WorkType.DictWorkCategoryScheduleId === 'cc5e1966-5c83-412c-81d6-36f968d38ce8')
            ) {
              isNextSemester = false;

              if (item.semesters[nextSemesterIndex]?.semesterBegin === null) {
                semesterNumber =
                  item.semesters[nextSemesterIndex]?.semesterNumber;
                //semestrBeginWeekNumber.push(type.WeekNumber);
                //this.educationPlanScheduleService.semestersByCourse[item.courseNumber][nextSemesterIndex].semesterBeginWeekNumber = type.WeekNumber;
              }

              if (nextSemesterIndex < item.semesters.length - 1)
                nextSemesterIndex++;
            }

            if (
              type.WorkType.DictWorkCategoryScheduleId === 'd36da979-6286-4a9f-8476-aedfe22cd374'
            ) {
              isNextSemester = true;
            }*/

            const currentSemesterNumber = type.SemesterNumber ?? semesterNumber;
            const currentSemester = item.semesters.find((semester) =>
              semester.semesterBeginWeekNumber! <= type.WeekNumber);

            const installationSessionBeginWeek = this.findWeek(currentSemester?.installationSessionBegin, graphDates);
            const installationSessionEndWeek = this.findWeek(currentSemester?.installationSessionEnd, graphDates);

            const isInstallationSession = item.semesters.some((x) => this.InstallationSession(x, graphDates, type.WeekNumber))

            const installationSessionBeginDayNumber = this.getWeekDayNumber(installationSessionBeginWeek?.start, currentSemester?.installationSessionBegin);
            const installationSessionEndDayNumber = this.getWeekDayNumber(installationSessionEndWeek?.start, currentSemester?.installationSessionEnd);

            weeks.push({
              installationSessionBeginWeekNumber: installationSessionBeginWeek?.weekNumber,
              installationSessionEndWeekNumber: installationSessionEndWeek?.weekNumber,
              isInstallationSession: isInstallationSession,
              installationSessionBeginDayNumber,
              installationSessionEndDayNumber,

              //semestrBeginWeekNumber,
              weekNumber: type.WeekNumber,
              //semesterNumber,
              days: [
                {
                  daysAmount: type.DaysAmount,
                  symbol: type.WorkType.Symbol,
                  backgroundColor: type.WorkType.Color,
                  dictWorkScheduleStudyProcessType: type.WorkType,
                  semesterNumber: type.SemesterNumber,
                },
              ],
            });
          } else {
            const currentWeek = weeks.find(
              (item) => item.weekNumber === type.WeekNumber
            );
            if (currentWeek)
              currentWeek.days[type.SerialNumberWeek] = {
                daysAmount: type.DaysAmount,
                symbol: type.WorkType.Symbol,
                backgroundColor: type.WorkType.Color,
                dictWorkScheduleStudyProcessType: type.WorkType,
                semesterNumber: type.SemesterNumber,
              };
          }
          return weeks;
        },
        []
      );
      let offset = weeksNumber[0].weekNumber - 1; //сделать неактивные ячейки
      /*while (offset && this.emptyCell) {
        weeksNumber.unshift({
          weekNumber: offset,
          semesterNumber: 0,
          days: [
            {
              daysAmount: 6,
              semesterNumber: 0,
              backgroundColor: this.emptyCell.Color,
              dictWorkScheduleStudyProcessType: {
                ...this.emptyCell,
                //id: this.emptyCell.DictWorkScheduleStudyProcessTypeExternalId,
                DictWorkCategoryScheduleId: this.emptyCell.DictWorkCategoryScheduleId,
              },
              symbol: this.emptyCell.Symbol,
            },
          ],
        });
        offset--;
      }*/
      /*var offsetWeek = Math.min(...weeksNumber.map((week) => week.weekNumber)) - 1;
      if(offsetWeek != 0)
          weeksNumber.forEach((x, index) => x.weekNumber = index - offsetWeek + 1)*/

      if (this.emptyCell)
        for (var i = 1; i <= graphDates.length; i++)
        {
          if (!weeksNumber.some((s) => s.weekNumber == i)) {
            weeksNumber.push({
              weekNumber: i,
              semesterNumber: 0,
              installationSessionBeginDayNumber: 0,
              installationSessionEndDayNumber: 0,
              days: [
                {
                  daysAmount: 6,
                  semesterNumber: 0,
                  backgroundColor: this.emptyCell.color,
                  dictWorkScheduleStudyProcessType: {
                    Color: this.emptyCell.color,
                    Symbol: this.emptyCell.symbol,
                    Id: this.emptyCell.dictWorkCategoryScheduleId,
                    //id: this.emptyCell.DictWorkScheduleStudyProcessTypeExternalId,
                    CategoryId:
                      this.emptyCell.dictWorkCategoryScheduleId,
                  },
                  symbol: this.emptyCell.symbol,
                },
              ],
            });
          }
          if(weeksNumber.length == graphDates.length)
            break;
        }

      /*offset = Math.max(...weeksNumber.map(week => week.weekNumber))
      while(offset < graphDates.length && this.emptyCell)
      {
        weeksNumber.push({
          weekNumber: offset,
          semesterNumber: 0,
          days: [
            {
              daysAmount: 6,
              semesterNumber: 0,
              backgroundColor: this.emptyCell.color,
              dictWorkScheduleStudyProcessType: {
                Color: this.emptyCell.color,
                Symbol: this.emptyCell.symbol,
                //id: this.emptyCell.DictWorkScheduleStudyProcessTypeExternalId,
                DictWorkCategoryScheduleId: this.emptyCell.dictWorkCategoryScheduleId,
              },
              symbol: this.emptyCell.symbol,
            },
          ],
        })
        offset++;
      }*/
      templateTableData.push({ courseNumber: item.courseNumber, weeksNumber: orderBy(weeksNumber, this.sortEmpty) });
    });
    this.tableData = templateTableData;
  }

  public getTableHeaderData(dates: any[]) {
    this.tableHeaderData = [];
    let templateHeaderData: TableHeaderType[] = [];

    dates.forEach((date) => {
      const dateStart = new Date(date.start);
      const dateEnd = new Date(date.end);

      if (dateStart.getMonth() !== dateEnd.getMonth()) {
        templateHeaderData.push({
          days: `${dateStart.getDate()} - ${dateEnd.getDate()}`,
          title: date.title,
        });
      } else {
        if (
          templateHeaderData.at(-1)?.month !== undefined &&
          templateHeaderData.at(-1)?.month === dateStart.getMonth().toString()
        ) {
          templateHeaderData.at(-1)?.weeks?.push({
            days: `${dateStart.getDate()} - ${dateEnd.getDate()}`,
            title: date.title,
          });
        } else {
          templateHeaderData.push({
            month: dateStart.getMonth().toString(),
            weeks: [
              {
                days: `${dateStart.getDate()} - ${dateEnd.getDate()}`,
                title: date.title,
              },
            ],
          });
        }
      }
    });

    this.tableHeaderData = templateHeaderData;
  }

  public getStudGroupEducationProgramByStudentId(studentId: string) {
    this.studEduGroupService.getEducationProgramByStudentId(studentId)
      .subscribe(
        response => {
          this.studGroupEducationProgram = response;
        }
      )
  }

  removeDuplicateSemesters(dataItem: any) {
    let result = [...new Set(dataItem.map((x:any) => x.SemesterNumber + "(" + x.DictControlActionAbbreviation + ")"))]
      .reduce((res, item:any) => res + ', ' + item.toString(), '') as string;
    if(result.length > 0)
      result = result.slice(2)
    return result;
  }

  displayCompetenceMatrix(dataItem: any) {
    let string = ""
    for (let t = 0; t < dataItem.length; t++) {
      string = string + dataItem[t].Code + ', ';
    }
    string = string.slice(0, -2);
    return string;
  }

  public getStudyFormByStudentId(studentId: string) {
    this.studEduGroupService.getStudyFormByStudentId(studentId)
      .subscribe(
        response => {
          this.studyForm = response;
        }
      )
  }

  public getStudEduGroup(studentId: string) {
    this.studEduGroupService.getStudEduGroup(studentId)
    .subscribe(
      response => {
        this.studEduGroup = response;
        this.groupEducationPlan = this.studEduGroup?.eduGroup?.planId;
      }
    );
  }

  //Getting student by login
  public getCurrentStudent() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    this.studentService.getCurrentStudent()
      .subscribe(
        response => {
          this.students = response;
          if (changeStudent) {
            this.studentModel = this.students[changeStudent - 1];
          } else {
            this.studentModel = this.students[0];
          }
          this.getStudGroupEducationProgramByStudentId(`${this.studentModel.externalId}`);
          this.getStudyFormByStudentId(`${this.studentModel.externalId}`);
          this.getStudEduGroup(`${this.studentModel.externalId}`);
          this.getStudPlan(`${this.studentModel.externalId}`);
        },
          error => {
          this.hasLoaded = true;
          }
      );
  }

  public getDictWorkScheduleStudyProcessType() {
    this.dictWorkScheduleStudyProcessTypeService.getAllDictWorkSchedule()
      .subscribe({
        next: (response) => {
          this.mapLegend = this.trainingLevelId
            ? this.filterScheduleLegent(response)
            : response;
        }}
      );
  }

  // Change cell color depending on value
  public cellStyle = (color:string) => {
    return 'background-color: ' + color;
  }
  public rowClassCallback = (context: RowClassArgs) => {
    const dataLevel = context.dataItem.Level;
    return {
      planLevel0: dataLevel === 0,
      planLevel1: dataLevel === 1,
      planLevel2: dataLevel > 1,
      noActive: context.dataItem.Active == false,
    };
  }

  public disciplineCellClick(cell: CellClickEvent): void {
    if(cell.dataItem.Id!=null)
      this.router.navigate(['education/discipline/' + cell.dataItem.Id]);
  }

  public competenceMatrixCellClick(cell: CellClickEvent): void {
    this.selectedDisciplineId = cell.dataItem.Id;
  }

  public navigateToCompetenceMatrix() {
    setTimeout(() => this.router.navigate(['education/competenceMatrix/' + this.selectedDisciplineId]), 50 )
  }

  ngOnInit(): void {
    //this.getEducationPlans();
    this.getCurrentStudent();
    this.getDictWorkScheduleStudyProcessType();
  }

  public InstallationSession = (semester: any, dates: any, weekNumber: any) => {
    const semesterBegin = this.findWeek(semester?.installationSessionBegin, dates)
    const semesterEnd = this.findWeek(semester?.installationSessionEnd, dates)
    return (semesterBegin?.weekNumber && semesterEnd?.weekNumber
      ? weekNumber >= semesterBegin?.weekNumber
      && weekNumber <= semesterEnd?.weekNumber : false);
  }

  private findWeek(date: string | null | undefined, weeks: any[]) {
    return !date ? null :
      weeks.find(
        (week) => DateFromUTCAsLocal(week.start) <= DateFromUTCAsLocal(date)
          && DateFromUTCAsLocal(week.end) >= DateFromUTCAsLocal(date));
  }

  private getWeekDayNumber(startWeekDate: string | null | undefined, date: string | null | undefined) {
    if (startWeekDate && date) {
      const firstDayNumber = new Date(startWeekDate).getDay();
      let secondDayNumber = new Date(date).getDay();

      secondDayNumber += secondDayNumber < firstDayNumber ? 7 : 0;
      return secondDayNumber - firstDayNumber;
    }

    return 0;
  }
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

function sumTypeWorkHours(disciplines: [], typeWorkName:string): number {
  return disciplines.filter((item: any) => !item.DictComponent.HiddenLaborIntensity)
    .flatMap((item:any) => item.ControllingActions)
    .flatMap((item:any) => item.TypeWorks)
    .filter((item: any) => item.Name == typeWorkName)
    .reduce((sum, current:any) => sum + current.Value, 0);
}

function sumHours(disciplines: [], cycleId:string|null, componentId:string|null): number {
  return disciplines.filter((item: any) =>
    item.Cipher &&
    !item.DictComponent.HiddenLaborIntensity &&
    (cycleId == null || item.DictCycleId == cycleId) &&
    (componentId == null || item.DictComponent.Id == componentId || item.DictComponent.ParentDictComponent?.Id == componentId)
  ).reduce((sum, current:any) => sum + current.TotalNumberHours, 0);
}

function sumIntensity(disciplines: [], cycleId:string|null, componentId:string|null): number {
  return disciplines.filter((item: any) =>
    item.Cipher &&
    !item.DictComponent.HiddenLaborIntensity &&
    (cycleId == null || item.DictCycleId == cycleId) &&
    (componentId == null || item.DictComponent.Id == componentId || item.DictComponent.ParentDictComponent?.Id == componentId)
  ).reduce((sum, current:any) => sum + current.TotalLaborIntensity, 0);
}

function sumContactWork(disciplines: [], semester:number|null): number {
  let dataArr = disciplines.filter((item: any) => item.Cipher && !item.DictComponent.HiddenLaborIntensity)
    .flatMap((item:any) => item.ControllingActions)
    .filter((item: any) => semester == null || item.SemesterNumber == semester);
  return dataArr.reduce((sum, item:any) => sum + item.HoursContactWork, 0);
}
function sumSelfWork(disciplines: [], semester:number|null): number {
  let dataArr = disciplines.filter((item: any) => !item.DictComponent.HiddenLaborIntensity)
    .flatMap((item:any) => item.ControllingActions)
    .filter((item: any) => semester == null || item.SemesterNumber == semester);
  return dataArr.reduce((sum, item:any) => sum + item.HoursSelfWork, 0);
}
function sumControl(disciplines: [], semester:number|null): number {
  let dataArr = disciplines.filter((item: any) => !item.DictComponent.HiddenLaborIntensity)
    .flatMap((item:any) => item.ControllingActions)
    .filter((item: any) => semester == null || item.SemesterNumber == semester);
  return dataArr.reduce((sum, item:any) => sum + item.Control, 0);
}

function countActions(actions: [], semester:number, controlActionId:string|null, isCourseWork:boolean|null, isCourseProject:boolean|null): number {
  return actions.filter((item: any) =>
    item.SemesterNumber == semester &&
    (controlActionId==null || item.DictControlActionId == controlActionId) &&
    (isCourseProject==null || item.HasCourseProject==isCourseProject) &&
    (isCourseWork==null || item.HasCourseWork==isCourseWork)
  ).length;
}

export function DateFromUTCAsLocal(date: any): Date {
  if(date == null) return date;
  return new Date(typeof date == "string" ? `${date}`.replace('Z','') : date)
}
