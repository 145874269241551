import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import { JwtHelperService } from '@auth0/angular-jwt';
import {TokenStorageService} from "../services/token.service";
import {environment} from '../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private jwtHelper: JwtHelperService, private router: Router, private tokenStore: TokenStorageService) {

  }

  canActivate(){
    const token = this.tokenStore.getAuthToken()
    let user;
    let role;
    if (token !== null) {
      user = this.jwtHelper.decodeToken(token);
      role = user.role;
    }

    if (token && !this.jwtHelper.isTokenExpired(token) && 
    user.stud_person_id !== undefined){
      return true
    }

    if(role?.includes('admin'))
      return true;

    if (user.stud_person_id === undefined) {
      alert(environment.invalidTokenMessage);
    }

    localStorage.removeItem('switchStudent');
    localStorage.removeItem('changeStudent');

    this.router.navigateByUrl("/login")
    return false;
  }
}
