import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DictAttestation } from 'src/app/models/progress/dictAttestation.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictAttestationService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.DictAttestation}`;

  constructor(private http: HttpClient) { }

  //Get DictAttestation
  public GetDictAttestations(): Observable<DictAttestation[]> {
    return this.http.get<DictAttestation[]>(this.baseUrl + '/GetDictAttestations/')
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
