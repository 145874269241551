import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { CurrentControl } from 'src/app/models/progress/currentControl.model';
import { StudentMark } from 'src/app/models/progress/studentMark.model';
import { StudentSemester } from 'src/app/models/progress/studentSemester.model';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import { CurrentControlService } from 'src/app/services/LKStudyProgress/current-control.service';
import { MiddleControlService } from 'src/app/services/LKStudyProgress/middle-control.service';
import { DictAttestationService } from 'src/app/services/LKStudyProgress/dict-attestation.service';
import { DictAttestation } from 'src/app/models/progress/dictAttestation.model';

@Component({
  selector: 'lkprogress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  public lastSemester: number = 0;
  public hideCurrentControl: boolean = false;
  public loading: boolean = false;
  public currentControlLoading: boolean = false;

  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: '',
    studentNumber: '',
    studPersonId: '',
    studPerson: this.studPerson
  }

  public currentControls: CurrentControl[] = [];
  public dictAttestations: DictAttestation[] = [];
  public middleControls: StudentMark[] = [];
  public studentSemesters: StudentSemester[] = [];

  public semesterForm: FormGroup = new FormGroup({
    id: new FormControl(),
    externalId: new FormControl(),
    studentId: new FormControl(),
    semestrBegin: new FormControl(),
    semestrEnd: new FormControl(),
    semestrNum: new FormControl(),
    courseNum: new FormControl(),
  })

  constructor(
      private currentControlService: CurrentControlService,
      private middleControlService: MiddleControlService,
      private studentService: LKStudentService,
      private dictAttestationService: DictAttestationService) { }

  ngOnInit(): void {
    this.getDictAttestations();
    this.getCurrentStudent();
  }

    //Getting student by login
    private getCurrentStudent() {
        const changeStudent = Number(localStorage.getItem('changeStudent'));
        this.studentService.getCurrentStudent()
            .subscribe(
                response => {
                    this.students = response;
                    if (changeStudent) {
                        this.studentModel = this.students[changeStudent - 1];
                    } else {
                        this.studentModel = this.students[0];
                    }
                    this.getStudentSemesters(`${this.studentModel.externalId}`);
                }
            );
    }

  // Get DictAttestations
  private getDictAttestations() {
    this.dictAttestationService.GetDictAttestations()
        .subscribe(
            response => {
              this.dictAttestations = response;
            }
        );
  }

  public getMarkValue(dataItem: any) {
    switch(dataItem) {
      case 0:
        return 'Не зачтено';
      case -1:
        return 'Неявка по уважительной причине';
      case -2:
        return 'Неявка по неуважительной причине';
      case -3:
        return 'Не допущен';
      case 6:
        return 'V';
      default:
        return dataItem;
    }
  }

  // Получение оценок промежуточного контроля
   private getMiddleControlStudentMarksByStudentSemester(studentId: string, studentSemester: number) {
    this.loading = true;
    this.middleControlService.getStudentMarksByStudentSemester(studentId, studentSemester)
        .subscribe(
            response => {
              this.middleControls = response;
              this.loading = false;
            },
            error => {
              this.loading = false;
            }
        )
  }
  // Получение оценок текущего контроля
  private getCurrentControlsByStudentSemester(studentId: string, studentSemester: number) {
    this.currentControlLoading = true;
    this.currentControlService.getCurrentControlsByStudentSemester(studentId, studentSemester)
        .subscribe(
            response => {
              this.currentControls = response;
              this.currentControlLoading = false;
            },
            error => {
              this.currentControlLoading = false;
            }
        )
  }

  public valueChange(value: any): void {
    this.getCurrentControlsByStudentSemester(`${this.studentModel.externalId}`, value);
    this.getMiddleControlStudentMarksByStudentSemester(`${this.studentModel.externalId}`, value);
    /*const filtered = this.studentSemesters.filter(item => item.externalId === value);
    if(filtered[0].courseNum >= 3)
    {
      this.hideCurrentControl = true;
    }
    else*/ {
      this.hideCurrentControl = false;
    }
  }

  public currentControlMarks(dictAttestationId: string, dataItem: any, fieldName: string) {
    // Проверяем совпадает ли Id контрольной недели
    let att = dataItem.attestations.filter((item: any) => item.dictAttestationId == dictAttestationId);
    if(att.length>0) {
      // Выводим данные в завимости от названия поля
      switch(fieldName) {
        case 'hoursAbsent':
          return att[0].hoursAbsent;
        case 'ball':
          return att[0].ball;
        default:
          return '-'
      }
    }

    return '-';
  }

  // Get student semesters
  private getStudentSemesters(studentId: string) {
    this.middleControlService.getStudentSemesters(studentId)
        .subscribe(
            response => {
              this.studentSemesters = response;
              if(response.length !== 0) {
                this.semesterForm.value.id = response[response.length-1].id;
                this.semesterForm.value.externalId = response[response.length-1].externalId;
                this.lastSemester = response[response.length-1].semestrNum;
                /*if(response[response.length-1].courseNum >= 3)
                {
                  this.hideCurrentControl = true;
                }*/
                if(this.lastSemester !== 0) {
                  this.getCurrentControlsByStudentSemester(`${this.studentModel.externalId}`, this.lastSemester);
                  this.getMiddleControlStudentMarksByStudentSemester(`${this.studentModel.externalId}`, this.lastSemester);
                }
              }
            }
        )
  }
}