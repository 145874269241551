<div class="discipline" style="">
  <div>
    <h2>{{disciplineName}}</h2>
    <h3 class="discipline__department">{{departmentName}}</h3>
  </div>
  <button kendoButton class="returnBtn" (click)="returnToEducation()">Назад</button>
</div>
<!--<h3 style="margin-top: 4px;">Общие сведения о дисциплине</h3>
<kendo-grid [data]="disciplineData" scrollable="none" style="width: 75%">
    <kendo-grid-column headerClass="grid-header" title="Кафедра" field="DepartmentName">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell" style="text-align: center">
                {{dataItem.DepartmentName}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="grid-header" title="Цикл" field="DictCycleName">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell" style="text-align: center">
                {{dataItem.DictCycleName}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="grid-header" title="Компонент" field="DictComponentName">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell" style="text-align: center">
                {{dataItem.DictComponentName}}
            </span>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>-->
<h3 class="controlAction__title">Распределение контрольных акций</h3>
<kendo-grid class="k-mt-5 centered-grid discipline__table" scrollable="none" [data]="controlActionsData">
    <ng-template kendoGridNoRecordsTemplate></ng-template>
    <kendo-grid-column headerClass="grid-header" field="name" title="Семестр" [width]="300">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell center">
                {{dataItem}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngFor="let action of controllingActions"
                       headerClass="grid-header" [title]="action.SemesterNumber.toString()" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="gridCell center" [innerHTML]="findValue(action, dataItem)">
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

<h3 class="controlAction__title">Распределение нагрузки (в часах)</h3>
<kendo-grid class="k-mt-5 centered-grid discipline__table" scrollable="none" [data]="labourHours">
    <ng-template kendoGridNoRecordsTemplate></ng-template>
    <kendo-grid-column headerClass="grid-header" field="Name" title="Семестр" [width]="300">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="gridCell {{dataItem.ClassroomWork ? 'k-pl-4' : ''}}">
                {{dataItem.Name}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="80" headerClass="grid-header" *ngFor="let semester of activeSemesters" [title]="semester.number">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="gridCell center">
          {{intensityCount(dataItem, semester)}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="80" headerClass="grid-header"  *ngFor="let work of additionalWorks" [title]="work.ShortName">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="gridCell center">
            {{additionalHours(dataItem.Name, work.Name)}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="grid-header" [width]="150" title="Итого">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="gridCell center">
            {{totalCount(dataItem)}}
        </span>
        <span class="gridCell center" [ngSwitch]="dataItem.Name">
            <span *ngSwitchCase="'Контроль'">{{controlSum}}</span>
            <span *ngSwitchCase="'Контактная работа'">{{contactSum}}</span>
            <span *ngSwitchCase="'Перезачтенные часы'">{{hoursCreditedSum}}</span>
            <span *ngSwitchCase="'Самостоятельная работа'">{{selfWorkSum}}</span>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
