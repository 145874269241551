import { Component, Input, OnInit} from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Comments } from 'src/app/models/announcement/comments.model';
import { CommentsService } from 'src/app/services/announcement/comments.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { PostComment } from 'src/app/models/announcement/post-comment.model';
import { paperclipIcon } from "@progress/kendo-svg-icons";
import { environment } from 'src/environments/environment';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})


export class CommentsComponent  implements OnInit{
    @Input() refreshComments : number = 0;
    @Input() authorId : number = 0;

    baseUrl = environment.announcementApiEndpoint + 'announcement';

    public comments: Comments[] = [];
    public userId: number = 0;
    public messageId: number = 0;

    public post: string = "";
    public commentAttachments: [] = [];

    public externalId: string = "";
    public quantityComments: number = 0;
        
    constructor(
        private activatedroute: ActivatedRoute,
        private commentsService: CommentsService,
        private studentService: LKStudentService,
        private http: HttpClient,
        private router: Router,
    ) { }

    public getComments(id: number) {
        this.commentsService.getComments(id)
          .subscribe(response => {
            this.comments = response;
            this.quantityComments = this.comments.length;
            this.messageId = +id;
          });
    }

    public getFile(fileid: string, filename: string){
      var url = this.baseUrl + "/getfile?fileid=" + fileid + "&filename=" + filename;
      this.http.get(url, { responseType: 'blob' })
        .subscribe((response) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename) {
            downloadLink.setAttribute('download', filename);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink)
        });
    }

    public deleteComment(comment_id: number){
      this.commentsService.deleteComments(comment_id)
        .subscribe(() => {
          this.getComments(this.activatedroute.snapshot.params['id']);
        }); 
    }

    public getCurrentPerson(){
      this.studentService.getCurrentStudent()
        .subscribe(response => {
          let data: any = response;
          this.externalId = data["0"].externalId;
        });
  }

    ngOnInit() {
        this.getComments(this.activatedroute.snapshot.params['id']);

        this.getCurrentPerson();
    }   

    ngOnChanges(){
      this.getComments(this.activatedroute.snapshot.params['id']);
    }
}
