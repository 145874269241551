var studentName="N/A";

export const menuItems: any[] = [
    {
      text: `${studentName}`,
      items: [
        { text: "Личный кабинет", icon: "k-icon k-i-user" },
        //{ text: "Настройки", icon: "k-icon k-i-gear"},
        //{ text: "Сменить пользователя", icon: "k-icon k-i-circle"},
        { text: "Выход", icon: "k-icon k-i-logout"}
      ],
    }
]
