import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CurrentControl } from 'src/app/models/progress/currentControl.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrentControlService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.CurrentControl}`;

  constructor(private http: HttpClient) { }

  //Get CurrentControls by student semester
  public getCurrentControlsByStudentSemester(studentId: string, studentSemesterId: number): Observable<CurrentControl[]> {
    return this.http.get<CurrentControl[]>(this.baseUrl + '/GetCurrentControlsByStudentSemester/' + studentId + '/' + studentSemesterId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
