import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { dictWorkCategoryScheduleEnum } from 'src/app/models/education/dictWorkCategorySchedule.enum';
import {
  CellDataType,
  CellDayType,
} from 'src/app/models/education/education-plan-schedule.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'educational-process-schedule-cell',
  templateUrl: './educational-process-schedule-cell.component.html',
  styleUrls: ['./educational-process-schedule-cell.component.scss'],
})
export class EducationalProcessScheduleCellComponent implements OnInit {
  @Input() weekInfo: CellDataType = {
    semestrBeginWeekNumber: [],
    installationSessionBeginDayNumber: 0,
    installationSessionEndDayNumber: 0,
    days: [],
    weekNumber: 0,
  };
  @Input() currentWeekNumber: number = 0;
  @Input() course: number = 0;
  @Input() isChoosedCells: boolean = false;
  @Input() isDisplayDot: boolean = false;

  days: CellDayType[] = this.getDefaultDays(this.weekInfo.days[0] || {});

  isWholeWeek: boolean = true;
  isSelectedCell: boolean = false;
  isSemesterBegin: boolean = false;
  isInstallationSession: boolean = false;
  isInstallationSessionBegin: boolean = false;
  isInstallationSessionEnd: boolean = false;
  currentDay: number = 0;
  public holidaysEnum: any = dictWorkCategoryScheduleEnum;

  constructor() {}

  ngOnInit(): void {
    if (this.weekInfo === undefined) {
      this.weekInfo = {
        semestrBeginWeekNumber: [],
        installationSessionBeginDayNumber: 0,
        installationSessionEndDayNumber: 0,
        days: [
          {
            daysAmount: environment.projecting.daysInWeek,
            symbol: '',
            backgroundColor: '#ffffff',
          } as CellDayType,
        ],
        weekNumber: 0,
      };
    }

    this.isSemesterBegin = !!this.weekInfo.semestrBeginWeekNumber?.includes(
      this.currentWeekNumber
    );

    this.isInstallationSession = this.weekInfo.isInstallationSession ? this.weekInfo.isInstallationSession: false
    this.isInstallationSessionBegin = this.weekInfo.installationSessionBeginWeekNumber === this.currentWeekNumber;
    this.isInstallationSessionEnd = this.weekInfo.installationSessionEndWeekNumber === this.currentWeekNumber;

    if (this.weekInfo.days.length > 1) {
      this.isWholeWeek = false;
      let fillStart = 0;
      this.weekInfo.days.forEach((dayInfo) => {
        this.days.fill(
          { ...dayInfo, daysAmount: 1 },
          fillStart,
          fillStart + dayInfo.daysAmount
        );
        fillStart += dayInfo.daysAmount;
      });
    }

    if (this.isWholeWeek)
      this.days = this.getDefaultDays(this.weekInfo.days[0] || {});
  }

  public getDefaultDays(cell?: CellDayType) {
    return Array(environment.projecting.daysInWeek).fill({
      ...cell,
      daysAmount: 1,
      symbol: cell?.symbol || '',
      backgroundColor: cell?.backgroundColor || '#ffffff',
    });
  }
}
