import {Component, OnInit} from "@angular/core";
import {environment} from "../../../environments/environment";

@Component(
  {
    templateUrl: "loginpage.component.html",
    styleUrls: ["loginpage.component.scss"],
    selector: "login-page"
  }
)

export class LoginpageComponent implements OnInit{

  ngOnInit() {
    window.location.href = this.authUrlGenerate()
  }

  public login(){
    window.location.href = this.authUrlGenerate()
  }

  private authUrlGenerate(){
    let url = `${environment.authEndPoint}/idp/sso/oauth`;
    url +=
      "?client_id=" +
      environment.trustedNetClientId +
      "&redirect_uri=" +
      encodeURIComponent(`${window.location.protocol}//${window.location.host}/callback`) +
      "&scope=userprofile"
     ;
    return url
  }
}
