import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit {

  constructor() { }

  public gridData = [
    { subject: "Психологическое сопровождение пациентов в общей медицинской практике", data: "23.01.2022", location: "400(III)", fio:"Иванов Иван Иванович" },
    { subject: "Психология, психопатология и психотерапия зависимостей", data: "23.01.2022", location: "400(III)", fio:"Иванов Иван Иванович"  },
    { subject: "Типология личностных расстройств", data: "23.01.2022", location: "400(III)", fio:"Иванов Иван Иванович" },
  ];

  public coursesData = [
    {id: 1, name: "1 курс"},
    {id: 2, name: "2 курс"},
    {id: 3, name: "3 курс"},
  ]

  ngOnInit(): void {
  }

}
