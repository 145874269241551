<div style="margin: 0 -16px">
    <kendo-tilelayout [resizable]="true">
        <kendo-tilelayout-item title="Мое расписание занятий">
            <kendo-tilelayout-item-body>
                <div class="dropdownBlock">
                    <div class="dropdownChild">
                        <h3>Выберите неделю:</h3>
                    </div>
                    <div class="dropdownChild">
                        <kendo-dropdownlist [data]="coursesData" textField="name" valueField="id" [valuePrimitive]="true">
                        </kendo-dropdownlist>
                    </div>
                </div>
                <kendo-grid [data]="gridData" [style.height]="'100%'">
                    <kendo-grid-column field="id" title="№" [width]="100"></kendo-grid-column>
                    <kendo-grid-column field="subject" title="Дисциплина" [width]="100"></kendo-grid-column>
                    <kendo-grid-column field="date" title="Дата" [width]="80" format="n0">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.date + " " + dataItem.time}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="location" title="Расположение" [width]="80" format="n0"></kendo-grid-column>
                    <kendo-grid-column field="fio" title="Преподаватель" [width]="80" format="n0"></kendo-grid-column>
                </kendo-grid>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
    </kendo-tilelayout>
</div>