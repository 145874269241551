import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DictQualification } from 'src/app/models/education/DictQualification.model';
import { EducationStandard } from 'src/app/models/education/EducationStandard.model';
import { LKEducationPlan } from 'src/app/models/education/LKEducationPlan.model';
import { LKEducationProgram } from 'src/app/models/education/LKEducationProgram.model';
import { LKStudPlan } from 'src/app/models/education/LKStudPlan.model';
import { DictStudyForm } from 'src/app/models/profile/dictionaries/DictStudyForm.model';
import { Department } from 'src/app/models/profile/external/department.model';
import { Faculty } from 'src/app/models/profile/external/faculty.model';
import { LKEduGroup } from 'src/app/models/profile/lkEduGroup.model';
import { LKStudEduGroup } from 'src/app/models/profile/lkStudEduGroup.model';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { LKStudEduGroupService } from 'src/app/services/LKStudent/lkstud-edu-group.service';
import { LKStudPlanService } from 'src/app/services/LKStudent/lkstud-plan.service';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';

@Component({
  selector: 'app-competence-matrix',
  templateUrl: './competence-matrix.component.html',
  styleUrls: ['./competence-matrix.component.scss']
})
export class CompetenceMatrixComponent implements OnInit, AfterViewChecked {

  // Название дисциплины
  public disciplineName? = "-";
  private idNumber = String(this.route.snapshot.paramMap.get('id'));
  public loading = false;

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public studyForm: DictStudyForm = {
    studyFormName: '',
    studyFormSName: ''
  }

  public educationStandard: EducationStandard = {
    cipher: '',
    name: '',
    shortName: '',
    dictStandardTypeId: '',
    dictEducationLevelId: '',
    dictTrainingLevelId: ''
  }

  public faculty: Faculty = {
    name: '-',
    shortName: '-'
  }

  public department: Department = {
    name: '-',
    shortName: '-',
    faculty: this.faculty
  }

  public dictQualification: DictQualification = {
    qualificationName: '',
    qualificationSName: ''
  }

  public educationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    dictQualification: this.dictQualification,
    departmentId: '',
    graduatingDepartment: this.department,
    faculty: this.faculty
  }

  public educationPlans: LKEducationPlan[] = [];
  public educationPlan: LKEducationPlan = {
    educationPlanId: 0,
    educationPlanExternalId: "",
    jsonData: "",
    educationProgramId: '',
    educationProgram: this.educationProgram,
    dictStudyFormId: '',
    dictStudyForm: this.studyForm
  }

  public eduGroup?: LKEduGroup = {
    courseNum: 0,
    groupName: "-",
    faculty: this.faculty,
    facultyId: ""
  }

  public studEduGroup?: LKStudEduGroup = {
    studPersonId: '',
    fullName: '',
    studentId: '',
    eduGroupId: '',
    eduGroup: this.eduGroup
  }

  public studPlan?: LKStudPlan = {
    studentId: '',
    educationPlan: this.educationPlan,
    department: this.department
  }

  public competenceMatrixData: any[] = [];

  constructor(private router: Router,
    private studPlanService: LKStudPlanService,
    private studEduGroupService: LKStudEduGroupService,
    private studentService: LKStudentService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.getCurrentStudent();
  }

  returnToEducation() {
    this.router.navigate(['education/3']);
  }


  //Getting student by login
  private getCurrentStudent() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    this.studentService.getCurrentStudent()
      .subscribe(
        response => {
          this.students = response;
          if (changeStudent) {
            this.studentModel = this.students[changeStudent - 1];
          } else {
            this.studentModel = this.students[0];
          }
          this.getStudEduGroup(`${this.studentModel.externalId}`);
          this.getStudPlan(`${this.studentModel.externalId}`);
        }
      );
  }

  private getStudEduGroup(studentId: string) {
    this.studEduGroupService.getStudEduGroup(studentId)
    .subscribe(
      response => {
        this.studEduGroup = response;
      }
    );
  }

  private getStudPlan(studentId: string) {
    this.loading = true;
    this.studPlanService.getStudPlan(studentId)
        .subscribe(
            async response => {
              this.studPlan = response;
              await delay(500);
              this.loading = false;

              // Вывод базового учебного плана в таблицу
              if (this.studPlan?.educationPlan?.jsonData != null || this.studPlan?.educationPlan?.jsonData != undefined) {
                const arr = JSON.parse(this.studPlan?.educationPlan?.jsonData);
                const disciplines = arr.Disciplines;
                const filtered = disciplines.filter((item: { Id: string; }) => item.Id === this.idNumber);

                // Вывод названия дисциплины
                this.disciplineName = filtered[0].DictDisciplineName;

                for (var i = 0; i < filtered.length; i++) {
                  for (var t = 0; t < filtered[i].DistributionCompetences.length; t++) {
                    this.competenceMatrixData.push(filtered[i].DistributionCompetences[t])
                  }
                }
              } else if (this.studEduGroup?.eduGroup?.educationPlan?.jsonData != null) {
                const arr = JSON.parse(this.studEduGroup!.eduGroup!.educationPlan!.jsonData);
                //console.log("JSON:", arr);
                const disciplines = arr.Disciplines;
                const filtered = disciplines.filter((item: { Id: string; }) => item.Id === this.idNumber);

                // Вывод названия дисциплины
                this.disciplineName = filtered[0].DictDisciplineName;

                for (var i = 0; i < filtered.length; i++) {
                  for (var t = 0; t < filtered[i].DistributionCompetences.length; t++) {
                    this.competenceMatrixData.push(filtered[i].DistributionCompetences[t])
                  }
                }
              }
            },
            error => {
              this.loading = false;
            })
  }
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
