import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EducationGraphLegend } from 'src/app/models/education/LKEducationPlan.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictWorkScheduleStudyProcessTypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.WorkScheduleType}`;

  constructor(private http: HttpClient) { }

  //Get all BudgetTimes
  public getAllDictWorkSchedule(): Observable<EducationGraphLegend[]> {
    return this.http.get<EducationGraphLegend[]>(this.baseUrl + '/GetWorkScheduleStudyProcessTypes');
  }
}
