import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, filter, Observable, switchMap, take, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {JwtHelperService} from "@auth0/angular-jwt";
import {TokenStorageService} from "../services/token.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor( private route: Router, private tokenStorageService: TokenStorageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        return next.handle(request).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    //console.log('status code ' + err.status);
                    switch ((<HttpErrorResponse>err).status) {
                        case 401:
                            this.route.navigateByUrl('login');
                            return throwError(() => err);

                            break;
                        default:
                            //this.notification.showNotification("error",
                            // err?.error?.message ?? "Произошла ошибка при обработке запроса");
                            return throwError(() => err);
                    }
                }
                else return throwError(() => err);
            })
        );
    }



}
