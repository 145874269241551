import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKStudPlan } from 'src/app/models/education/LKStudPlan.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKStudPlanService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.StudPlan}`;

  constructor(private http: HttpClient) { }

  //Get all Stud Plans
  public getAllStudPlans(): Observable<LKStudPlan[]> {
    return this.http.get<LKStudPlan[]>(this.baseUrl + '/GetStudPlans/')
      .pipe(
        catchError(this.handleError));
  }

  //Get Stud Plan
  public getStudPlan(studentId: string): Observable<LKStudPlan> {
    return this.http.get<LKStudPlan>(this.baseUrl + '/GetStudPlan/' + studentId)
      .pipe(
        catchError(this.handleError));
  }


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
