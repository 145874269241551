<div class="title-comments" *ngIf="quantityComments > 0">Комментарии</div>  

<div class="comment" *ngFor="let comment of comments">
    <div class="comment-info">
        <div class="text-comment">
            {{comment.text}}
        </div>
        <div class="display-f cursor" *ngIf="comment.userExternalId == externalId" (click)="deleteComment(comment.id)">
            <div class="delete-comment m-r5">Удалить</div>
            <div class="delete-comment">x</div>
        </div>
    </div>
    <ng-container  *ngFor="let file of comment.commentAttachments">
        <span class="k-icon k-i-paperclip a-span m-r10"></span>
        <span class="cursor m-0-auto" (click)="getFile(file['externalId'],file['fileName'])" class="file-comment">
            {{file["fileName"]}}
        </span>
    </ng-container>
    <div class="data-comments">
        {{comment.fio}},  {{comment.post}}  {{comment.date | date:'dd.MM.yyyy HH:mm'}}
    </div>
</div>

