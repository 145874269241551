import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AlertAnnouncement } from '../../models/announcement/alert.model';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

  export class AlertService {
 //   baseUrl = urlJoin(environment.announcementApiEndpoint, environment.apiPaths.announcement.base);
    baseUrl = environment.announcementApiEndpoint + 'announcement';

//baseUrl = 'https://test-lks-api.uni-systems.ru/announcement';


  constructor(private http: HttpClient) { }

 public getSearchMessages(page: number, type: number, status: number, query: string, tag: string, start: string, finish: string): Observable<AlertAnnouncement>{
  let params = new HttpParams();
  if (page !== undefined){
    params = params.append('page', page);
  }
  if (type !== 2){
    params = params.append('type', type);
  }
  if (status !== 0){
  params = params.append('status', status);
  }
  if (query !== ""){
    params = params.append('query', query);
  }
  if (tag !== ""){
   params = params.append('tag', tag);
  }
  if ( start !== ""){
  params = params.append('start', start);
  }
  if ( start !== ""){
  params = params.append('finish', finish);
  }
    return this.http.get<AlertAnnouncement>(this.baseUrl + '/get', {params});
  }


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}